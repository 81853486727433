<template>
  <v-list-item-content>
    <v-list-item-title>{{ `${item.surname} ${item.name}` }}</v-list-item-title>
    <v-list-item-subtitle>
      {{ item.login }}
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
export default {
  props: {
    item: { type: Object, default: () => {} },
  },
};
</script>
